import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Layout } from '../components/shared/Layout';
import Img from 'gatsby-image';

const IndexPage = ({
  data: { file },
}: {
  data: { file: any };
}): JSX.Element => {
  return (
    <Layout>
      <main className="text-center">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Voor jouw unieke ideeën!</h1>
            <h2 className="mb-16">Samen maken we Den Haag meer AWESOME</h2>
            <Img fluid={file.childImageSharp.fluid} />
            <div className="grid md:grid-cols-2 md:gap-4 mf-16">
              <div className="p-16 ">
                <h2>I have an idea</h2>
                <p>
                  Do you have an idea that makes The Hague more lovely, fun
                  &amp; awesome?
                </p>
                <a
                  href="https://www.awesomefoundation.org/en/submissions/new?chapter=thehague"
                  className="text-xl"
                >
                  Apply now
                </a>
                <Link to="#idea" className="mx-4 text-xl underline">
                  Read more
                </Link>
              </div>
              <div className="p-16 ">
                <h2>I want to become a trustee</h2>
                <p>
                  Do you want to want to make The HAgue more awesome by donating
                  €50 a few times a year?
                </p>
                <Link to="/contact" className="text-xl">
                  Apply now
                </Link>
                <Link to="#trustee" className="mx-4 text-xl underline">
                  Read more
                </Link>
              </div>
            </div>
            <p>
              Awesome The Hague geeft zes keer per jaar €1.000,- weg om jouw
              stoutste dromen voor Den Haag te realiseren! Het meest awesome
              idee krijgt van ons (twintig Haagse trustees) €1000,- cash,
              netwerk en onze kennis om jouw idee te realiseren.
            </p>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto">
            <h2>Dit is AWESOME</h2>
            <p>
              The Awesome Foundation is een wereldwijde community die awesome in
              het universum verder brent. Trustees ondersteunen te gekke ideeën
              in meer dan 80 chapters in 18 landen. Kijk voor meer informatie op
              de <a href="https://awesomefoundation.org">wereldwijde website</a>
              .
            </p>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto">
            <h2>Direct contact?</h2>

            <p>
              Join the Awesome{' '}
              <a href="https://t.me/joinchat/AHrH1hlCr1e2JzMbO0X5xw">
                <FontAwesomeIcon icon={faTelegram} /> Telegram Group
              </a>
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "pexels-kaboompics-com-6385.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
